
import { Component, Vue, Watch } from 'vue-property-decorator'
import * as Request from '@/types/network/request'

@Component
export default class Login extends Vue {
    email = ''
    password = ''
    errorMessages = ''
    formHasErrors = false
    errorsDisplayed = false
    waitingForResponse = false

    get jwToken(): string {
        return this.$store.getters['identity/jwToken']
    }

    get refreshToken(): string {
        return this.$store.getters['identity/refreshToken']
    }

    get errors(): string[] {
        return this.$store.getters['identity/errors']
    }

    get refreshRequest(): Request.Refresh {
        return {
            token: this.jwToken,
            refreshToken: this.refreshToken
        }
    }

    get form () : any {
        return {
          email: this.email,
          password: this.password
        }
    }

    @Watch("$store.state.identity.errors", { deep: true })
    private onErrorsUpdate(event: any) : void {
        event.length > 0 && (this.errorsDisplayed = true)
    }

    login() : void {
        this.formHasErrors = false
        Object.keys(this.form).forEach(f => {
          if (!this.form[f]) this.formHasErrors = true
            const ref : any = this.$refs[f]
            ref.validate(true)
        })

        if (!this.formHasErrors) {
            let returnedFromRequest = false

            setTimeout(() => {
                if (!returnedFromRequest)
                    this.waitingForResponse = true
            }, 100)
            
            this.$store
                .dispatch('identity/login', this.form)
                .finally(() => (returnedFromRequest = true) && (this.waitingForResponse = false))
        }
    }
  
}
